import { type ReactElement, useCallback, useContext } from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import ThemeContext from 'shared/containers/theme-context/ThemeContext';

import useIsCondorEmployee from 'shared/api/hooks/useIsCondorEmployee';

function ThemeMode(): ReactElement | null {
  const { themeMode, setThemeMode } = useContext(ThemeContext);
  const isCondorEmployee = useIsCondorEmployee();

  const changeThemeMode = useCallback(
    (mode: 'dark' | 'light') => {
      if (mode !== themeMode) {
        setThemeMode(mode);
        localStorage.setItem('theme', mode);
      }
    },
    [themeMode, setThemeMode],
  );

  return isCondorEmployee ? (
    <>
      <Divider orientation="vertical" sx={{ my: 1.5, mr: 2 }} flexItem />
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          bgcolor: 'grey.100',
          borderRadius: 2,
          px: 1.2,
          py: 1,
        }}
      >
        <Box
          sx={{ display: 'contents', cursor: 'pointer' }}
          onClick={() => changeThemeMode('light')}
        >
          {themeMode === 'light' ? (
            <LightModeIcon sx={{ color: 'primary.main' }} />
          ) : (
            <LightModeOutlinedIcon sx={{ color: 'text.secondary' }} />
          )}
        </Box>
        <Box />
        <Box
          sx={{ display: 'contents', cursor: 'pointer' }}
          onClick={() => changeThemeMode('dark')}
        >
          {themeMode === 'dark' ? (
            <DarkModeIcon sx={{ color: 'primary.main' }} />
          ) : (
            <DarkModeOutlinedIcon sx={{ color: 'text.secondary' }} />
          )}
        </Box>
      </Stack>
    </>
  ) : null;
}
export default ThemeMode;
