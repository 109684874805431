/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

When using ag-grid, please ensure that everything passed to it is fully memoized.

As you can see in the component, every non-scalar value is behind a useMemo
and every function is behind a useCallback to ensure this component honors the memo mandate.

If you make changes, and do not continue this pattern, it will cause
random, inconsistent glitching of the grid ranging from the appearance of
loss of data, to rows re-ordering, to data flickering, and more.

If you have any questions, please ask in #engineering
*/

import type { ForwardedRef } from 'react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ProcessRowGroupForExportParams } from '@ag-grid-community/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import Box from '@mui/material/Box';
import { type SxProps, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import AgGridCheckboxCellEditor from 'shared/components/ag-grid-cells/editors/ag-grid-checkbox-editor/AgGridCheckboxCellEditor';
import AgGridDateCellEditor from 'shared/components/ag-grid-cells/editors/ag-grid-date-cell-editor/AgGridDateCellEditor';
import AgGridPercentEditor from 'shared/components/ag-grid-cells/editors/ag-grid-percent-editor/AgGridPercentEditor';
import AgGridSelectEditor from 'shared/components/ag-grid-cells/editors/ag-grid-select-editor/AgGridSelectEditor';
import AgGridTextEditor from 'shared/components/ag-grid-cells/editors/ag-grid-text-editor/AgGridTextEditor';
import AgGridContractStatusRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-contract-status-cell-renderer/AgGridContractStatusRenderer';
import AgGridCustomCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-custom-cell-renderer/AgGridCustomCellRenderer';
import AgGridEmptyCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-empty-cell-renderer/AgGridEmptyCellRenderer';
import AgGridFileHyperlinkCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-file-hyperlink-cell-renderer/AgGridFileHyperlinkCellRenderer';
import AgGridGroupCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-group-cell-renderer/AgGridGroupCellRenderer';
import AgGridMoneyCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-money-cell-renderer/AgGridMoneyCellRenderer';
import AgGridOccContractContainerAddNewCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-occ-contract-container-add-new-cell-renderer/AgGridOccContractContainerAddNewCellRenderer';
import AgGridOccContractContainerGroupCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-occ-contract-container-group-cell-renderer/AgGridOccContractContainerGroupCellRenderer';
import AgGridOverUnderPercentageCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-overunder-percentage-cell-renderer/AgGridOverUnderPercentageCellRenderer';
import AgGridRecordCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-record-cell-renderer/AgGridRecordCellRenderer';
import AgGridTrialAccessCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-trial-access-cell-renderer/AgGridTrialAccessCellRenderer';
import AgGridTrialFileDeleteCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-trial-file-delete-cell-renderer/AgGridTrialFileDeleteCellRenderer';
import AgGridUserManagementCheckboxCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-user-management-checkbox-renderer/AgGridUserManagementCheckboxCellRenderer';
import AgGridUserStatusCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-user-status-cell-renderer/AgGridUserStatusCellRenderer';
import AgGridVarianceFilter from 'shared/components/ag-grid-filters/ag-grid-variance-filter/AgGridVarianceFilter';

import config from 'config';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';

import convertColDef from './helpers/convertColDef';
import convertColDefs from './helpers/convertColDefs';
import convertGridOptions from './helpers/convertGridOptions';
import { filterDateValueFromClipboard } from './helpers/shared';
import type {
  CondorAgGridProps,
  CondorColDef,
  CondorGridOptions,
} from './types';

import './style.scss';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ClipboardModule,
  ExcelExportModule,
  MenuModule,
  RowGroupingModule,
  SetFilterModule,
  ServerSideRowModelModule,
]);

LicenseManager.setLicenseKey(
  // cspell: disable-next-line
  'Using_this_{AG_Grid}_Enterprise_key_{AG-057278}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Condor_Software}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Condor_Software}_only_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Condor_Software}_need_to_be_licensed___{Condor_Software}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_May_2025}____[v3]_[01]_MTc0Nzg2ODQwMDAwMA==ca193ac59cf38636ee96f56b6858f4a1',
);

const genericGridOptions: CondorGridOptions = {
  rowHeight: 36,
  headerHeight: 40,
  groupDefaultExpanded: -1,
  suppressScrollOnNewData: true,
  singleClickEdit: true,
  processCellFromClipboard: filterDateValueFromClipboard,
};

const genericColDef: CondorColDef = {
  useValueFormatterForExport: true,
};

type Props<TData> = CondorAgGridProps<TData> & {
  loading?: boolean;
  sx?: SxProps;
  userDisplayOptions?: UserDisplayOptions;
};

function CondorAgGrid<TData>(
  props: Props<TData>,
  ref: ForwardedRef<AgGridRef<TData> | undefined>,
) {
  const {
    columnDefs,
    defaultColDef,
    gridOptions,
    loading,
    sx,
    userDisplayOptions,
    context,
    overlayNoRowsTemplate = 'No rows to show.',
    ...otherOptions
  } = props;

  const gridRef = useRef<AgGridReact<TData>>(null);
  const selectedRows = useRef<unknown[]>([]);
  const navigate = useNavigate();
  const themeMode = useTheme().palette.mode;

  // ag-grid returns null, but react seems only to accept undefined
  useImperativeHandle(ref, () => gridRef.current ?? undefined, []);

  useEffect(() => {
    if (loading === undefined || gridRef.current?.api == null) {
      return;
    }

    if (loading) {
      gridRef.current.api.setGridOption('loading', true);
    } else {
      gridRef.current.api.setGridOption('loading', false);
    }
  }, [loading]);

  const finalGridOptions = useMemo(() => {
    const options = convertGridOptions(navigate, selectedRows, themeMode, {
      ...genericGridOptions,
      ...gridOptions,
    });

    // fixing "groupDefaultExpanded is not supported with the 'serverSide' row model."
    if (options?.rowModelType === 'serverSide') {
      options.groupDefaultExpanded = undefined;
    }

    return options;
  }, [gridOptions, themeMode, navigate]);

  const finalColumnDefs = useMemo(
    () => convertColDefs(navigate, themeMode, columnDefs),
    [columnDefs, themeMode, navigate],
  );

  const finalDefaultColDef = useMemo(
    () =>
      convertColDef(navigate, themeMode, {
        ...genericColDef,
        ...defaultColDef,
      }),
    [defaultColDef, themeMode, navigate],
  );

  // Registering renderers here allows us to refer to them by name
  const components = useMemo(
    () => ({
      // RENDERERS
      AgGridContractStatusRenderer,
      AgGridUserStatusCellRenderer,
      AgGridCustomCellRenderer,
      AgGridTrialAccessCellRenderer,
      AgGridEmptyCellRenderer,
      AgGridFileHyperlinkCellRenderer,
      AgGridGroupCellRenderer,
      AgGridMoneyCellRenderer,
      AgGridOccContractContainerAddNewCellRenderer,
      AgGridOccContractContainerGroupCellRenderer,
      AgGridOverUnderPercentageCellRenderer,
      AgGridRecordCellRenderer,
      AgGridTrialFileDeleteCellRenderer,
      AgGridUserManagementCheckboxCellRenderer,

      // EDITORS
      AgGridDateCellEditor,
      AgGridPercentEditor,
      AgGridSelectEditor,
      AgGridTextEditor,
      AgGridCheckboxCellEditor,

      // FILTERS
      AgGridVarianceFilter,
    }),
    [],
  );

  useEffect(() => {
    if (gridRef.current?.api && userDisplayOptions) {
      gridRef.current.api.refreshCells({ force: true });

      if (
        gridOptions?.rowModelType === undefined ||
        gridOptions.rowModelType === 'clientSide'
      ) {
        gridRef.current.api.refreshClientSideRowModel('aggregate');
      }
    }
  }, [userDisplayOptions, gridOptions?.rowModelType]);

  const finalContext = useMemo(
    () => ({
      ...context,
      ...userDisplayOptions,
      ...gridOptions?.context,
    }),
    [context, userDisplayOptions, gridOptions?.context],
  );

  // for some reason, the grouped rows, in either gridDisplayType we use, don't have the correct text, so this puts it back
  const finalExportParams = useMemo(
    () => ({
      processRowGroupCallback: ({
        node,
        api,
      }: ProcessRowGroupForExportParams) => {
        const gridDisplayType = api.getGridOption('groupDisplayType');

        // this is the "grand total" row at the bottom
        if (node.key === null) {
          return gridDisplayType === 'singleColumn' ? 'Total' : '';
        }

        // this is the "total" row at the bottom of the group
        if (
          node.sibling?.rowIndex != null && // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- this appears to be a bug in ag grid typing as this can be undefined (https://condor-software.sentry.io/issues/5999336628/)
          node.rowIndex != null &&
          node.sibling.rowIndex <= node.rowIndex
        ) {
          return gridDisplayType === 'singleColumn' ? `Total ${node.key}` : '';
        }

        // this is the "group" row at the top of the group
        return `${node.key}${node.allChildrenCount ? ` (${node.allChildrenCount})` : ''}`;
      },
    }),
    [],
  );

  return (
    <Box
      className={`ag-theme-condor ${themeMode === 'dark' ? 'ag-theme-condor-dark-mode' : 'ag-theme-condor-light-mode'}`}
      sx={sx}
    >
      <AgGridReact<TData>
        ref={gridRef}
        columnDefs={finalColumnDefs}
        components={components}
        context={finalContext}
        defaultColDef={finalDefaultColDef}
        defaultCsvExportParams={finalExportParams}
        defaultExcelExportParams={finalExportParams}
        gridOptions={finalGridOptions}
        overlayLoadingTemplate="<span>Please wait... loading</span>"
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        // These are suppressed only when under test as enabling them causes the tests to be "flaky".
        // This is still testing the validity of the grid as virtualization is a UI trick that doesn't affect data
        suppressColumnVirtualisation={config.IS_CYPRESS_ENVIRONMENT}
        suppressRowVirtualisation={config.IS_CYPRESS_ENVIRONMENT}
        // Fix a bug with Excel (Windows) that adds an extra empty line at the end of ranges copied to the clipboard.
        suppressLastEmptyLineOnPaste
        {...otherOptions}
      />
    </Box>
  );
}

// Redeclare forwardRef to get proper behavior of generic typehinting
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-shadow -- https://fettblog.eu/typescript-react-generic-forward-refs/
  function forwardRef<T, P = object>(
    render: (props: P, ref: Ref<T>) => ReactNode | null,
  ): (props: P & RefAttributes<T>) => ReactNode | null;
}

// this appears to be a mistyped type in ag-grid (https://github.com/ag-grid/ag-grid/issues/9231) that says the API is not optional, when it clearly is
export type AgGridRef<TData> = Omit<AgGridReact<TData>, 'api'> & {
  api?: AgGridReact<TData>['api'];
};
export type UserDisplayOptions = { currencyViewMode: CurrencyViewMode };

export default forwardRef(CondorAgGrid);
