import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { OccExpenseSummaryRow } from 'shared/lib/types';

export default function useOccExpenseSummaryGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<OccExpenseSummaryRow> {
  const navigate = useNavigate();
  return useMemo(
    () => ({
      rowSelection: 'single',
      rowStyle: { cursor: 'pointer' },
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
      // TODO: re-enable this once we correctly get contract container slugs
      // onSelectionChanged: () => {      //   const selectedRows = gridRef.current?.api?.getSelectedRows();
      //   if (selectedRows?.length === 1) {      //     navigate(routes.getOccByContractContainer(selectedRows[0].contract_container_trace_id));
      //   }
      // },
    }),
    [navigate],
  );
}
