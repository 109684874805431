import { useMemo } from 'react';

import type { CellClickedEvent } from '@ag-grid-community/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSelectCellConfig,
  getUserManagementCheckboxCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { UserPermissions, UserRow } from 'shared/lib/types';
import { UserStatusOptions } from 'shared/lib/types';
import { setCurrentUserInUserManagement } from 'shared/state/slices/userManagementSlice';
import { selectUser } from 'shared/state/slices/userSlice';

export default function useUserAccessColumnDefs(
  isOpenPeriod: boolean,
  setShowTrialAccessDialog: (showTrialAccessDialog: {
    state: boolean;
    trace_id: string;
    name: string;
    trials: string[];
    permissions: Partial<Record<keyof UserPermissions, boolean>>;
  }) => void,
  setShowAddUserDialog: (showAddUserDialog: boolean) => void,
): CondorColDef[] {
  const isLockGridEnabled = useFeatureFlag('lock_grid');
  const canEditCompanyUsersAndPermissions = useHasPermission([
    'canEditCompanyUsersAndPermissions',
  ]);

  const hasPermissionsAndIsOpenPeriod =
    isOpenPeriod && canEditCompanyUsersAndPermissions;
  const dispatch = useDispatch();

  const currentUserTraceId = useSelector(selectUser);

  return useMemo(() => {
    // Company group is set to true only for company groups
    const editableCheckForCompanyGroup = (data: UserRow) =>
      data.is_company_group === false;

    const nullAndTraceIdCheck = (data: UserRow | undefined) =>
      data !== undefined &&
      data.trace_id !== currentUserTraceId &&
      editableCheckForCompanyGroup(data);

    const userActiveCheck = (data: UserRow | undefined) =>
      nullAndTraceIdCheck(data) && data?.status !== 'Inactive';

    const assignedPreparerOrReviewerCheck = (
      data: UserRow | undefined,
      field: 'is_assigned_preparer' | 'is_assigned_reviewer',
    ) => {
      // check if they have a list of trials they are the assigned preparer or reviewer on
      const trialsToAppend =
        data?.[field] != null &&
        data[field] !== undefined &&
        Array.isArray(data[field])
          ? data[field]
          : null;

      return trialsToAppend == null || trialsToAppend.length === 0;
    };

    return [
      {
        headerName: 'User',
        flex: 1,
        children: [
          {
            headerName: 'Name',
            field: 'name',
            pinned: 'left',
            cellRenderer: 'AgGridTrialAccessCellRenderer' as const,
            cellRendererParams: {
              useEmDash: false,
              hasPermissionsAndIsOpenPeriod,
            },
            ...(hasPermissionsAndIsOpenPeriod && {
              onCellClicked: ({ node, data }: CellClickedEvent) => {
                if (node.key === null && userActiveCheck(data)) {
                  dispatch(
                    setCurrentUserInUserManagement({
                      traceId: data.trace_id,
                      first_name: data.first_name,
                      last_name: data.last_name,
                      email: data.email,
                      title: data.title ?? '',
                      third_party_company: data.third_party_company_name
                        ? {
                            value: data.third_party_company_name,
                            label: data.third_party_company_name,
                          }
                        : '',
                      trial_access: data.trial_access ?? [],
                    }),
                  );
                  setShowAddUserDialog(true);
                  return null;
                }
              },
            }),
          },
          {
            headerName: 'companyName',
            field: 'company_name',
            rowGroup: true,
            hide: true,
            aggFunc: 'first',
          },
          {
            field: 'status',
            headerName: 'Status',
            ...getSelectCellConfig(),
            ...(hasPermissionsAndIsOpenPeriod
              ? makeEditableIf(({ data }) => nullAndTraceIdCheck(data))
              : { ...getUserManagementCheckboxCellConfig() }),
            cellRenderer: 'AgGridUserStatusCellRenderer',
            cellEditorParams: {
              options: Object.entries(UserStatusOptions).map(
                ([value, label]) => ({ value, label }),
              ),
            },
          },
          { headerName: 'Company', field: 'third_party_company_name' },
          {
            headerName: 'Trial Access',
            field: 'trial_access',
            cellRenderer: 'AgGridTrialAccessCellRenderer' as const,
            cellRendererParams: {
              formatter: 'multiple_trials' as const,
              useEmDash: false,
              hasPermissionsAndIsOpenPeriod,
            },
            ...(hasPermissionsAndIsOpenPeriod && {
              onCellClicked: ({ node, data }: CellClickedEvent) => {
                // if key exists, its a group so we don't want to
                // handle on click event for that
                if (node.key === null && userActiveCheck(data)) {
                  setShowTrialAccessDialog({
                    state: true,
                    trace_id: data.trace_id,
                    name: data.name,
                    trials: data.trial_access,
                    permissions: {
                      canEditCompanyUsersAndPermissions:
                        data.can_edit_company_users_and_permissions,
                    },
                  });
                  return null;
                }
              },
            }),
          },
        ],
      },
      {
        headerName: 'Profile',
        children: [
          { headerName: 'Email', field: 'email' },
          { headerName: 'Title', field: 'title' },
        ],
      },
      {
        headerName: 'Company Permissions',
        children: [
          {
            headerName: 'Can manage users and permissions',
            field: 'can_edit_company_users_and_permissions',
            headerTooltip:
              'User can add, edit, remove company users and manage user permissions. User will be able to access all trials.',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can edit company settings',
            field: 'can_edit_company_settings',
            headerTooltip:
              'User can edit the company settings, like the control listing',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can edit company level-trial info',
            field: 'can_edit_company_level_info',
            headerTooltip:
              'User can upload PO and invoice listings, edit FX rates and edit G/L accounts',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can delete company-level trial info',
            field: 'can_delete_company_level_info',
            headerTooltip:
              'User can delete G/L accounts and company files, including PO and invoice listings',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
        ],
      },
      {
        headerName: 'Trial Permissions',
        children: [
          {
            headerName: 'Can manage trial roles',
            field: 'can_manage_trial_roles',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
            headerTooltip:
              'User is allowed to change who the preparer and reviewer are on any trial they have access to',
          },
          {
            headerName: 'Can be preparer',
            field: 'can_signoff_as_preparer',
            headerTooltip:
              'User is allowed to be preparer for any trial they have access to. For the trials for which they are assigned the role, a preparer can edit trial data, open periods, and comment, but cannot close periods',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(
                ({ data }) =>
                  userActiveCheck(data) &&
                  assignedPreparerOrReviewerCheck(data, 'is_assigned_preparer'),
              )),
            ...getUserManagementCheckboxCellConfig({
              fieldToCheck: 'is_assigned_preparer',
              tooltip: 'User is the assigned preparer on ',
            }),
          },
          {
            headerName: 'Can be reviewer',
            field: 'can_signoff_as_reviewer',
            headerTooltip:
              'User is allowed to be reviewer for any trial they have access to. For the trials for which they are assigned the role, a reviewer can close periods and comment, but cannot open periods, or edit or delete trial data',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(
                ({ data }) =>
                  userActiveCheck(data) &&
                  assignedPreparerOrReviewerCheck(data, 'is_assigned_reviewer'),
              )),
            ...getUserManagementCheckboxCellConfig({
              fieldToCheck: 'is_assigned_reviewer',
              tooltip: 'User is the assigned reviewer on ',
            }),
          },
          {
            headerName: 'Can edit trials',
            field: 'can_edit_trial_info',
            headerTooltip:
              'User is allowed edit contracts, update the site & lab cost matrix, manage visits & procedures, upload EDCs, change activity drivers, and make reconciliation adjustments',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can delete trial info',
            field: 'can_delete_trial_info',
            headerTooltip:
              'User can delete CRO and OCC contracts, sites, labs, and trial files',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can open new periods',
            field: 'can_open_period',
            headerTooltip:
              'User can open new periods for trials they have access to',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can re-open periods',
            field: 'can_reopen_period',
            headerTooltip:
              'User can re-open periods for trials they have access to',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can comment',
            field: 'can_add_comments',
            headerTooltip:
              'User can comment and add attachments anywhere commenting is available at both the company and trial level',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can access forecasts',
            field: 'can_access_forecasting',
            headerTooltip:
              'User can view forecasts for trials they have access to',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          {
            headerName: 'Can create and edit forecasts',
            field: 'can_create_and_edit_forecasting',
            headerTooltip:
              'User can create, edit, lock, and unlock forecasts for trials they have access to',
            ...(hasPermissionsAndIsOpenPeriod &&
              makeEditableIf(({ data }) => userActiveCheck(data))),
            ...getUserManagementCheckboxCellConfig(),
          },
          ...(isLockGridEnabled
            ? [
                {
                  headerName: 'Can lock grids',
                  field: 'can_lock_grids',
                  headerTooltip: 'User can can lock and unlock grids',
                  ...(hasPermissionsAndIsOpenPeriod &&
                    makeEditableIf(({ data }) => userActiveCheck(data))),
                  ...getUserManagementCheckboxCellConfig(),
                },
              ]
            : []),
        ],
      },
    ];
  }, [
    setShowTrialAccessDialog,
    hasPermissionsAndIsOpenPeriod,
    currentUserTraceId,
    isLockGridEnabled,
    setShowAddUserDialog,
    dispatch,
  ]);
}
