import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { KeyValueRow } from 'shared/lib/types';

type Props = { record?: KeyValueRow[] };

function parseValue(value: number | string | null | undefined) {
  return value == null || value.toString().trim().length === 0 ? (
    <>&#8212;</>
  ) : (
    value
  );
}

function RecordViewer(props: Props) {
  const { record } = props;

  if (record === undefined) {
    return null;
  }

  return (
    <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 3 }}>
      {record.map((row) => {
        const value = Array.isArray(row.value)
          ? undefined
          : parseValue(row.value);
        const values = Array.isArray(row.value)
          ? row.value.length > 0
            ? row.value.map(
                (
                  arrayValue,
                  index,
                  // biome-ignore lint/suspicious/noArrayIndexKey: there is nothing else to use as the key
                ) => <Box key={index}>{parseValue(arrayValue)}</Box>,
              )
            : parseValue('')
          : undefined;

        return (
          <Box key={row.key}>
            <Typography variant="body2">{row.key}</Typography>
            <Typography color="text.secondary" variant="caption">
              {values ?? value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default RecordViewer;
