import type { ReactElement, ReactNode } from 'react';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

type Props = {
  children: ReactNode;
  showDivider?: boolean;
  sx?: SxProps<Theme>;
  title?: string;
  titleAction?: ReactElement;
};

function DashboardCard({
  title,
  titleAction,
  children,
  sx,
  showDivider,
}: Props) {
  return (
    <Paper sx={{ p: 4, ...sx }} square>
      {!!title && (
        <>
          <Stack
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            sx={!showDivider ? { mb: 2 } : {}}
          >
            <Typography variant="subtitle1">{title}</Typography>
            {titleAction}
          </Stack>
          {showDivider && <Divider sx={{ my: 2 }} />}
        </>
      )}
      {children}
    </Paper>
  );
}

export default DashboardCard;
